<template>
  <div>
    <c-tab
      type="horizon"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :dense="true"
      :height="tabHeight"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam="popupParam"
          :outernal.sync="outernal"
          :attachInfo.sync="attachInfo"
          :disabled.sync="disabled"
          :contentHeight.sync="contentHeight"
          @getDetail="getDetail"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'outernal-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiOuternalResultId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'info',
      outernal: {
        saiOuternalResultId: '',  // 외부심사결과 일련번호
        plantCd: '',  // 사업장코드
        outernalResultName: '',  // 외부심사명
        auditStartDate: '',  // 심사시작일
        auditEndDate: '',  // 심사종료일
        period: [],
        year: '',
        auditTargetDeptCd: '',  // 심사대상부서
        auditDeptCd: '',  // 심사주관부서
        saiOuternalAuditAgency: null,  // 주관심사기관
        auditPurpose: '',  // 심사목적
        imprDerivedItems: '',  // 개선도출항목
        auditResult: null,  // 심사결과
        auditResultSummary: '',  // 심사결과요약
        auditTargetDeptReviewOpinion: '',  // 심사대상부서검토의견
        regUserId: '',
        chgUserId: '',
        auditTeams: [], // 심사팀
        deleteAuditTeams: [], // 심사팀 [삭제]
        imprs: [], // 개선
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'OUTER_AUDIT_ATTACH',
        taskKey: '',
      },
      editable: true,
      detailUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
    tabItems() {
      let _items =  [
        { name: 'info', icon: 'info', label: '외부심사 정보', component: () => import(`${'./outernalInfo.vue'}`) },   
      ];
      if (this.popupParam.saiOuternalResultId) {
        _items.push({ name: 'impr', icon: 'info', label: '개선사항', component: () => import(`${'./outernalImpr.vue'}`) })   
      }
      return _items;
    },
    disabled() {
      return false;
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.outernal.result.get.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.saiOuternalResultId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.saiOuternalResultId);
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          this.$_.extend(this.outernal, _result.data)
          console.log(_result.data.imprs)
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
          this.$set(this.outernal, 'period', [this.outernal.auditStartDate, this.outernal.auditEndDate])
          this.$set(this.attachInfo, 'taskKey', this.popupParam.saiOuternalResultId)
        },);
      } else {
        // noting
      }
    },
    closePopup() {
      this.$emit('closePopup')
    }
  }
};
</script>